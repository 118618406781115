(function ($) {
  $(document).ready(function () {
    $(".gdpr-header").click(function () {
      var header = $(this);
      var content = header.next();
      header.toggleClass("expanded");
      content.slideToggle(200, function () {
        return content.is(":visible") ? "Collapse" : "Expand";
      });
    });
  });
})(jQuery);