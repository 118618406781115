(function ($) {

  /**
   * Attaches the menu toggle to the main nav.
   *
   * @type {Drupal~behavior}
   * 
   * @prop {Drupal~behaviorAttach} attach
   *   Show main menu after a user has clicket the main-menu-pull button.
   */
  Drupal.behaviors.ResponsiveMenuOld = {
    attach: function (context) {
      var pull        = $('.menu-block--main-menu-pull');
          menu        = $('.menu-block--main-menu');
          menuHeight  = menu.height();
   
      pull.once('menu_expanded', function() {
        pull.on('click', function(e) {
            e.preventDefault();
            menu.slideToggle();
            $(this).toggleClass('main-menu-pull--expanded');
        });
      });

      // Show old menu upon window resizing.
      $(window).resize(function(){
          var w = $(window).width();
          if(w > 768 && menu.is(':hidden')) {
              menu.removeAttr('style');
          }
      });

    }
  };

   /**
   * Attaches the menu toggle to the main nav.
   *
   * @type {Drupal~behavior}
   * 
   * @prop {Drupal~behaviorAttach} attach
   *   Show main menu after a user has clicket the main-menu-pull button.
   */
  Drupal.behaviors.ResponsiveMenu = {
    attach: function (context) {
      var pull = $('.main-menu-pull', context),
          menu = $('.main-menu', context);

      pull.once('menu_expanded', function() {
        pull.on('click', function(e) {
            e.preventDefault();
            menu.slideToggle();
            $(this).toggleClass('main-menu-pull--expanded');
        });
      });

      // Show old menu upon window resizing.
      $(window).resize(function(){
          var w = $(window).width();
          if(w > 768 && menu.is(':hidden')) {
              menu.removeAttr('style');
          }
      });

    }
  };


  /**
   * Attaches disable Enter key for listed selectors.
   *
   * @type {Drupal~behavior}
   * 
   * @prop {Drupal~behaviorAttach} attach
   *   Go through a list of selectors and disable the enter key.
   */
  Drupal.behaviors.DisableEnterKey = {
    attach: function (context) {
      // Put here all of the jquery selected objects for which you want to have the enter key disabled.
      var selectors = [$('.commerce-line-item-views-form ').find('input[type=text]')];

      $.each( selectors, function( index, value ){
          value.bind('keypress', function(e) {
           if(e.keyCode == 13)
           {
              return false;
           }
        });
      });

    }
  };

  /**
   * Behaviour for all checkboxes and radios on click.
   *
   * @type {Drupal~behavior}
   * 
   * @prop {Drupal~behaviorAttach} attach
   *   Add an active class on all radio and checkbox input wrappers to outlie them.
   */
  Drupal.behaviors.RadionCheckboxInputOutline = {
    attach: function (context) {
      var selectors = $('input[type=radio]:not([name="attributes[field_colour]"]), input[type=checkbox]');
      selectors.each( function (index) {

         $(this).once('checkbox_activated', function() {
           $(this).on('click', function(e) {

            // Search for a siblings with the same name and remove the border.
            var siblings = $(this).parent().parent().find('input[name="' + $(this).prop('name') + '"]');
            if (siblings.length > 0) {
              if (siblings.parent().prop('checked') === true) {
                siblings.parent().addClass('activated-checkbox');
              } else {
                siblings.parent().removeClass('activated-checkbox');
              }
            }

            // Add border around the parent.
            var container = $(this).parent();
            if ($(this).prop('checked') === true) {
              container.addClass('activated-checkbox');
            } else {
              container.removeClass('activated-checkbox');
            }
          });
        });
      });
    }
  };

  /**
   * Behaviour for all checkboxes on load.
   *
   * @type {Drupal~behavior}
   * 
   * @prop {Drupal~behaviorAttach} attach
   *   Add an active class on all radio and checkbox input wrappers to outlie them.
   *   This function is done because of the Ajax rebuilding the whole form.
   */
  Drupal.behaviors.RadionCheckboxInputOutlineOnLoad = {
    attach: function (context) {
      var selectors = $('.page-checkout').find('input[type=radio], input[type=checkbox]', context);

      selectors.each(function() {
        var container = $(this).parent();
        if ($(this).prop('checked') === true) {
          container.addClass('activated-checkbox');
        } else {
          container.removeClass('activated-checkbox');
        }
      });
    }
  };

  /**
   * Donation behaviour on checkout page step 2.
   *
   * @type {Drupal~behavior}
   * 
   * @prop {Drupal~behaviorAttach} attach
   *   Select the correct radio buttons on click and unselect upon 0 or empty 'other' value.
   */
  Drupal.behaviors.DonationRadioOtherAmountClick = {
    attach: function (context) {
      var otherText = $('#edit-cruk-commerce-donate-pane-input', context);
      var min = otherText.attr('donation-min');
      var max = otherText.attr('donation-max');
      var otherRadio = $('#edit-cruk-commerce-donate-pane-choice-', context);
      var gift_aid = $('.commerce_fieldgroup_pane__group_gift_aid');
      // Handle the other value on key input event.
      otherText.once('text_clicked', function() {
         otherText.on('keyup', function(e) {
          $('#edit-cruk-commerce-donate-pane-input', context).parent().find('.error').remove();
          // Search for number of decimals.
          var decimals = /\./.test($(this).val()) && $(this).val().match(/\..*/)[0].length - 1;

          if (min > 0 && parseFloat($(this).val()) < min) {
            $(this).val(min);
            $(this).parent().append('<span class="error">' + Drupal.t('The minimum amount to donate is: ') + min + '</span>');
            return;
          }

          if (max > 0 && parseFloat($(this).val()) > max) {
            $(this).val(max);
            $(this).parent().append('<span class="error">' + Drupal.t('The maximum amount to donate is: ') + max + '</span>');
            return;
          }

          // If number has more than 2 decimals then round to 2 decimals allways.
          if (decimals > 2) {
            otherText.val( Math.round($(this).val() * 100) / 100 );
          }

          // If input is not a number clear it from all unwanted characters and leave only numbers.
          if(isNaN($(this).val()) || $(this).val() < 0 ) {
            var newValue = $(this).val().toString().replace(/[^\d]/g, "");
            otherText.val( newValue.toString() );
          }

          // If the input value is larger than 0 then click the hidden 'other' radio button.
          if (otherText.val() > 0 && $(this).val() !== '') {
            otherRadio.trigger('click');
            otherText.parent().addClass('donate-other-activated');
            calculateGiftaid(otherText.val());
            gift_aid.slideDown(300);
          } else {
            otherText.parent().removeClass('donate-other-activated');
            gift_aid.slideUp(300);
          }
        });
      });

      // Other radios behaviour.
      var donation_radios = $('.form-item-cruk-commerce-donate-pane-choice input');
      donation_radios.each( function (index) {

        $(this).once('donation_radio_checked', function() {
          $(this).on('click', function(e) {
            $('#edit-cruk-commerce-donate-pane-input', context).parent().find('.error').remove();
            if ($(this).val() > 0 && $(this).val() !== '') {
              otherText.val($(this).val());
              if (min > 0 && parseFloat($(this).val()) < min) {
                otherText.val(min);
                otherText.parent().append('<span class="error">' + Drupal.t('The minimum amount to donate is: ') + min + '</span>');
              }

              if (max > 0 && parseFloat($(this).val()) > max) {
                otherText.val(max);
                otherText.parent().append('<span class="error">' + Drupal.t('The maximum amount to donate is: ') + max + '</span>');
              }
              calculateGiftaid($(this).val());
              gift_aid.slideDown(300);
              otherText.parent().addClass('donate-other-activated');
            }
          });
        });
      });

      function calculateGiftaid (ga_value) {

        var ga_before = $('#giftaid-before');
        var ga_after = $('#giftaid-after');

        var before_value = '£' + ga_value;
        var after_value = ((ga_value  * 1.25) % 1 !== 0) ? '£' + (ga_value  * 1.25).toFixed(2) : '£' + (ga_value  * 1.25);

        ga_before.text(before_value);
        ga_after.text(after_value);

      }
    }
  };

  /**
   * Small fix for order confirmation page.
   *
   * @type {Drupal~behavior}
   * 
   * @prop {Drupal~behaviorAttach} attach
   *   On mobile viewport (480px) transform word Quantity to Qty.
   */
  Drupal.behaviors.TransformQuantityToQty = {
    attach: function (context) {
      var w = $(window).width();
      if(w <= 480) {
        var qty = $('.thank-you__list-item--heading > .thank-you__list-item-cell--quantity', context);
        qty.text(Drupal.t('Qty.'));
      }
    }
  };

  /**
   * Behaviour for checkout form validation.
   *
   * @type {Drupal~behavior}
   * 
   * @prop {Drupal~behaviorAttach} attach
   *   Validate form on the checkout step 1.
   */
  Drupal.behaviors.CheckoutFormValidate = {
    attach: function (context) {
      var form = $('#commerce-checkout-form-checkout');
      var submit_button = $('#commerce-checkout-form-checkout').find('#edit-continue');
      var inputs = $('input', form);
      var selects = $('select', form);
      var form_validator = new CRUKValidator();

      submit_button.once('button_submitted', function() {
        submit_button.click( function (e) {
          e.preventDefault();
          
          $('.checkout-processing').css('display', 'none');
          $(this).attr('disabled', false);
          $(this).css('display', 'block');
          $(this).next().remove();

          
          if(form_validator.validateForm(validatorMappings)) {
            $('.checkout-processing').css('display', 'block');
            form.submit();
          } else {
            // Scroll to the first error
            if ($('.validation-error').length) {
              $('html, body').animate({
                scrollTop: $('.validation-error').offset().top - 120
              }, 500);
            }
          }
        });
      });

      inputs.each(function() {
        $(this).once('input_keyup', function() {
          $(this).blur( function (e) {
            form_validator.fieldValidation($(this), validatorMappings);
          });
        });
      });

      selects.each(function() {
        $(this).once('select_change', function() {
          $(this).change( function (e) {
            form_validator.fieldValidation($(this), validatorMappings);
          });
        });
      });
    }
  };

})(jQuery); 
