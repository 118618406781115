/**
 * This is a form validator designed for Drupal forms. jQuery is a dependancy for this object.
 */
var CRUKValidator  = function () {
  var self = this;

  /**
   * Main validation function which validates every field matched in the mapping.
   * 
   * @param {Object} mapping - mapping object used for form validation.
   * @return {Boolean} - returns true if form validation has passed, else returns false.
   */
  this.validateForm = function (mapping) {
    // Share mapping object across CRUKValidator object.
    self.mapping = mapping;
    // Remove validation messages if there are any.
    jQuery('.validation-error').remove();
    // Array of boolean values. Stores either true or false based on specific field validation.
    var fieldValidations = [];

    // First execute generalMapping validations.
    for (var i in mapping.generalMapping) {
      fieldValidations = self.generalValidation(mapping.generalMapping[i], 'generalMapping');
    }
    // Execute additionalMapping validations. This will overrride validations for a field that is within generalMapping selectors.
    for (var j in mapping.additionalMapping) {
      fieldValidations = jQuery.merge(fieldValidations, self.generalValidation(mapping.additionalMapping[j], 'additionalMapping'));
    }

    // If fieldValidations array contains just one false then validation has not passed.
    return (fieldValidations.toString().indexOf('false') !== -1) ? false : true;
  };

  /**
   * Specific field validation. Used for onKeyUp, onChange and field specific interactions.
   * 
   * @param {Object} field - field that we wish to validate.
   * @param {Object} mapping - mapping object used for form validation.
   * @return {Boolean} - returns true if form validation has passed, else returns false.
   */
  this.fieldValidation = function (field, mapping) {
    // Share mapping object across CRUKValidator object.
    self.mapping = mapping;
    // Remove validation messages if there are any.
    field.next('.validation-error').remove();
    // Array of boolean values. Stores either true or false based on specific field validation.
    var fieldValidations = [];

    // First execute generalMapping validations.
    for (var i in mapping.generalMapping) {
      fieldValidations = self.singleFieldValidation(field, mapping.generalMapping[i], 'generalMapping');
    }

    // Execute additionalMapping validations. This will overrride validations for a field that is within generalMapping selectors.
    for (var j in mapping.additionalMapping) {
      fieldValidations = jQuery.merge(fieldValidations, self.singleFieldValidation(field, mapping.additionalMapping[j], 'additionalMapping'));
    }

    // If fieldValidations array contains just one false then validation has not passed.
    return (fieldValidations.toString().indexOf('false') !== -1) ? false : true;
  };

  /**
   * This function is used because jQuery selectors can result in multiple objects.
   * We need to attach the validation on all of them.
   * 
   * @param {Object} mapping - mapping object used for form validation.
   * @param {String} mappingType - type of mapping that we are dealing with. For generalMapping we use overrideRule function.
   * @return {Array} validArray - array of boolean values, based on individual field validations.
   */
  this.generalValidation = function (mapping, mappingType) {
    // Array of boolean values. Stores either true or false based on specific field validation.
    var validArray = [];

    // Multiple or single field object based on the jQuery selector.
    var fields = jQuery(mapping.selector);

    fields.each(function() {
      // Validate each field individually and add the result to validArray.
      validArray.push(self.validateField( jQuery(this), mapping.rules, mappingType));
    });

    return validArray;
  };

  /**
   * This function is used because jQuery selectors can result in multiple objects.
   * We need to attach the validation on all of them.
   * This is used for single field validation.
   * 
   * @param {Object} field - field object that is being validated.
   * @param {Object} mapping - mapping object used for form validation.
   * @param {String} mappingType - type of mapping that we are dealing with. For generalMapping we use overrideRule function.
   * @return {Array} validArray - array of boolean values, based on individual field validations.
   */
  this.singleFieldValidation = function (field, mapping, mappingType) {
    // Array of boolean values. Stores either true or false based on specific field validation.
    var validArray = [];
    // Multiple or single field object based on the jQuery selector.
    var fields = jQuery(mapping.selector);

    fields.each(function() {
      // Initiate validation only if the field from mapping and the field that is beeing validated are the same.
      if (field.attr('id') === jQuery(this).attr('id')) {
        // Validate each field individually and add the result to validArray.
        validArray.push(self.validateField( jQuery(this), mapping.rules, mappingType));
      }
    });

    return validArray;
  };

  /**
   * Main function that validates field selection against its rules.
   * 
   * @param {Object} field - field object that is being validated.
   * @param {Array} rules - Array of rule objects that are executed.
   * @param {String} mappingType - type of mapping that we are dealing with. For generalMapping we use overrideRule function.
   * @return {Boolean} isValid - tells us if the rule has passed or not.
   */
  this.validateField = function (field, rules, mappingType) {
    // Set default values for the error message and isValid.
    var message = '';
    var isValid = true;

    for (var i in rules) {
      // Current rule.
      var rule_used = rules[i];

      // If mapping type is generalMapping then execute the overrideRule function for this field.
      if (mappingType === 'generalMapping' && self.overrideRule(field, rules[i])) {
        // If this type of rule for the field we are using exists in the additionalMapping then skip it.
        return;
      }

      // For different rule types execute different behaviour.
      switch(rule_used.type) {
        case 'required':
          // Look if the message has placeholders to replace with values.
          message = self.messagePlaceholders(rule_used.message, field);
          // If rule has conditions on it then check if the conditions are being satisfied else exit.
          if (!self.handleCondition(field, rule_used)) {
            break;
          }
          // If field value is empty then attach error message.
          if (field.val() === 0 || field.val() === '' || field.val() === 'undefined') {
            self.attachErrorMessage(field, message);
            isValid = false;
          }
          break;
        case 'min':
          // Look if the message has placeholders to replace with values.
          message = self.messagePlaceholders(rule_used.message, field);
          // If rule has conditions on it then check if the conditions are being satisfied else exit.
          if (!self.handleCondition(field, rule_used)) {
            break;
          }
          if (field.val().length < rule_used.value) {
            self.attachErrorMessage(field, message);
            isValid = false;
          }
          break;
        case 'max':
          // Look if the message has placeholders to replace with values.
          message = self.messagePlaceholders(rule_used.message, field);
          // If rule has conditions on it then check if the conditions are being satisfied else exit.
          if (!self.handleCondition(field, rule_used)) {
            break;
          }
          if (field.val().length > rule_used.value) {
            self.attachErrorMessage(field, message);
            isValid = false;
          }
          break;
        case 'regex':
          // Look if the message has placeholders to replace with values.
          message = self.messagePlaceholders(rule_used.message, field);
          // If rule has conditions on it then check if the conditions are being satisfied else exit.
          if (!self.handleCondition(field, rule_used)) {
            break;
          }
          // Execute the regular expression against the field value.
          if (!rule_used.value.test(field.val())) {
            self.attachErrorMessage(field, message);
            isValid = false;
          }
          break;
        case 'email':
          // Look if the message has placeholders to replace with values.
          message = self.messagePlaceholders(rule_used.message, field);
          // If rule has conditions on it then check if the conditions are being satisfied else exit.
          if (!self.handleCondition(field, rule_used)) {
            break;
          }
          // Email regex pattern.
          var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!pattern.test(field.val().trim())) {
            self.attachErrorMessage(field, message);
            isValid = false;
          }
          break;
        // Comparison rule type is used to compare field values with other field values.
        case 'comparison':
          // Look if the message has placeholders to replace with values.
          message = self.messagePlaceholders(rule_used.message, field);
          // If rule has conditions on it then check if the conditions are being satisfied else exit.
          if (self.handleCondition(field, rule_used)) {
            self.attachErrorMessage(field, message);
            isValid = false;
          }
          break;
      }
    }

    return isValid;
  };

  /**
   * This function handles conditions on rules.
   * 
   * @param {Object} field - field object that is being validated.
   * @param {Object} rule - rule that is beeing checked for conditions.
   * @return {Boolean} valid - is the rule valid or not.
   */
  this.handleCondition = function (field, rule) {
    // Rule is valid by default.
    var valid = true;
    // If the rule has any conditions attached to it.
    if (typeof rule.conditions !== 'undefined') {
      for (var i in rule.conditions) {
        // Field from the condition.
        var cond_field = jQuery(rule.conditions[i].selector);
        // If the condition has value set then use that in the stringOperator function for condition checking, else use condition field value.
        var check_operator = (typeof rule.conditions[i].value === 'undefined') ? self.stringOperator[rule.conditions[i].operator](cond_field.val(), field.val()) : self.stringOperator[rule.conditions[i].operator](cond_field.val(), rule.conditions[i].value);
        // If any of the conditions are not met then invalidate this rule.
        if (!check_operator) {
          valid = false;
          break;
        }
      }
    }
    return valid;
  };

  /**
   * Attach error message to the field.
   * 
   * @param {Object} field - field object that is being validated.
   * @param {String} message - error message to be displayed.
   */
  this.attachErrorMessage = function (field, message) {
    // Dispaly only one error message at the time.
    if (field.parent().find('.validation-error').length <= 0) {
      // Append the error message to the parent container as a last child.
      field.parent().append('<span class="validation-error">' + message + '</span>');
    }
  };

  /**
   * Check for placeholders in the error message and replace them.
   * 
   * @param {String} message - error message to be displayed.
   * @param {Object} field - field object that is being validated.
   * @return {String} error_message - is the rule valid or not.
   */
  this.messagePlaceholders = function (messages, field) {
    // Set default error mesasge to be the message value.
    var error_message = messages.value;

    // If message value has $field_label string then replace it with field label value.
    if (messages.value.indexOf('$field_label') !== -1) {
      var label_txt = field.parent().find('label').text();
      // If there are additional label selectors then use them to find the correct string.
      if (typeof messages.label !== 'undefined') {
        label_txt = field.parent().find('label').find(messages.label).text();
      }
      
      error_message = messages.value.replace('$field_label', label_txt);
    }

    // Check for replacements in the message. If there are any then replace them with replacements values.
    for (var key in messages) {
      if (key === 'replacements') {
        for (var str in messages.replacements) {
          error_message = error_message.replace(str, messages.replacements[str]);
        }
      }
    }

    return error_message;
  };

  /**
   * Check if field with the same rule type exists in the additionalMapping.
   * 
   * @param {Object} field - field object that is being validated.
   * @param {Object} rule - rule that is beeing checked.
   * @return {Boolean} - is this rule overriden or not.
   */
  this.overrideRule = function (field, rule) {
    // Go through additionalMapping rules.
    for (var i in self.mapping.additionalMapping) {
      // Field set that we need to cross reference with current field.
      var override_field = jQuery(self.mapping.additionalMapping[i].selector);
      for (var k = 0; k < override_field.length; k++) {
        // Check if the current field matches the field in this fieldset based on the id attribute.
        if (field.prop('id') === override_field[k].id) {
          for (var key in self.mapping.additionalMapping[i].rules) {
            // If this field has the same mapping type in additionalMapping rules then this is beeing overriden.
            if (self.mapping.additionalMapping[i].rules[key].type === rule.type) {
              return true;
            }
          }
        }
      }
    }
    return false;
  };

  /**
   * Helper object we use in the handleCondition function. Based on the key provided it mimics the operator.
   */
  this.stringOperator = {
    '=': function (x, y) {
      return x === y;
    },
    '<': function (x, y) {
      return x < y;
    },
    '>': function (x, y) {
      return x > y;
    },
    '<=': function (x, y) {
      return x <= y;
    },
    '>=': function (x, y) {
      return x >= y;
    },
    '!=': function (x, y) {
      return x != y;
    }
  };

};

