/**
 * This Javascript object mapps the checkout form fileds and adds relevant rules.
 * 
 * The object has two parts, generalMapping which we use for general rules that are used for multiple fields
 * and additionalMapping which we use to override generalMapping for specific fields and add specific rules.
 */
var validatorMappings = {
  generalMapping: [
    {
      selector: '.form-required > input',
      rules: [
        {
          type: 'required',
          message: {
            value: 'Please enter your $field_label',
          }
        }
      ]
    },
    {
      selector: '.form-required > select',
      rules: [
        {
          type: 'required',
          message: {
            value: 'Please select your $field_label',
          }
        }
      ]
    },
    {
      selector: '.street-block > .form-required > input, .locality-block > .form-required > input',
      rules: [
        {
          type: 'regex',
          value: /^([0-9a-zA-Z \-'.âãäåàáæçèéêëìíîïðñòóöőôõøùúûüűřžňýČÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖŐÙÚÛÜŰÝ])*$/i,
          message: {
            value: 'May contain only letters, numbers, hyphens, spaces, full stops, slashes and apostrophes',
          }
        },
        {
          type: 'min',
          value: 2,
          message: {
            value: Drupal.t('$field_label has a minimum length of $chars characters'),
            replacements: {
              $chars: 2
            }
          }
        },
        {
          type: 'max',
          value: 35,
          message: {
            value: Drupal.t('$field_label has a maximum length of $chars characters'),
            replacements: {
              $chars: 35
            }
          }
        },
      ]
    }
  ],
  additionalMapping: [
    {
      selector: '.form-required > #edit-account-login-mail',
      rules: [ 
        {
          type: 'email',
          message: {
            value: 'Please enter a valid email address',
          }
        },
        {
          type: 'max',
          value: 60,
          message: {
            value: Drupal.t('$field_label has a maximum length of $chars characters'),
            replacements: {
              $chars: 60
            }
          }
        }
      ],
    },
    {
      selector: '[name="customer_profile_shipping[commerce_customer_address][und][0][postal_code]"]',
      rules: [ 
        {
          type: 'regex',
          value: /^((BFPO ?[0-9]{1,4})|(GIR ?0AA)|(((A[BL]|B[ABDHLNRSTX]?|C[ABFHMORTVW]|D[ADEGHLNTY]|E[HNX]?|F[KY]|G[LUY]?|H[ADGPRSUX]|I[GMPV]|JE|K[ATWY]|L[ADELNSU]?|M[EKL]?|N[EGNPRW]?|O[LX]|P[AEHLOR]|R[GHM]|S[AEGKLMNOPRSTY]?|T[ADFNQRSW]|UB|W[ADFNRSV]|YO|ZE)[1-9]?[0-9]|((E|N|NW|SE|SW|W)1|EC[1-4]|WC[12])[A-HJKMNPR-Y]|(SW|W)([2-9]|[1-9][0-9])|EC[1-9][0-9]) ?[0-9][ABD-HJLNP-UW-Z]{2}))$/i,
          message: {
            value: 'Please enter a valid UK postcode',
          },
          conditions: [
            {
              selector: '[name="customer_profile_shipping[commerce_customer_address][und][0][country]"]',
              operator: '=',
              value: 'GB'
            }
          ]
        },
        {
          type: 'regex',
          value: /^([A-Z0-9 -])*$/i,
          message: {
            value: 'Only letters, numbers, hyphen, and space are used',
          }
        }
      ],
    },
    {
      selector: '[name="customer_profile_billing[commerce_customer_address][und][0][postal_code]"]',
      rules: [ 
        {
          type: 'regex',
          value: /^((BFPO ?[0-9]{1,4})|(GIR ?0AA)|(((A[BL]|B[ABDHLNRSTX]?|C[ABFHMORTVW]|D[ADEGHLNTY]|E[HNX]?|F[KY]|G[LUY]?|H[ADGPRSUX]|I[GMPV]|JE|K[ATWY]|L[ADELNSU]?|M[EKL]?|N[EGNPRW]?|O[LX]|P[AEHLOR]|R[GHM]|S[AEGKLMNOPRSTY]?|T[ADFNQRSW]|UB|W[ADFNRSV]|YO|ZE)[1-9]?[0-9]|((E|N|NW|SE|SW|W)1|EC[1-4]|WC[12])[A-HJKMNPR-Y]|(SW|W)([2-9]|[1-9][0-9])|EC[1-9][0-9]) ?[0-9][ABD-HJLNP-UW-Z]{2}))$/i,
          message: {
            value: 'Please enter a valid UK postcode',
          },
          conditions: [
            {
              selector: '[name="customer_profile_billing[commerce_customer_address][und][0][country]"]',
              operator: '=',
              value: 'GB'
            }
          ]
        },
        {
          type: 'regex',
          value: /^([A-Z0-9 -])*$/i,
          message: {
            value: 'Only letters, numbers, hyphen, and space are used',
          }
        }
      ],
    },
    {
      selector: '.first-name, .last-name',
      rules: [ 
        {
          type: 'min',
          value: 2,
          message: {
            value: Drupal.t('$field_label has a minimum length of $chars characters'),
            replacements: {
              $chars: 2
            }
          }
        },
        {
          type: 'max',
          value: 25,
          message: {
            value: Drupal.t('$field_label has a maximum length of $chars characters'),
            replacements: {
              $chars: 25
            }
          }
        },
        {
          type: 'regex',
          value: /^([a-zA-Z \-'.âãäåàáæçèéêëìíîïðñòóöőôõøùúûüűřžňýČÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖŐÙÚÛÜŰÝ])*$/i,
          message: {
            value: 'Please enter only one name containing only letters, hyphens, spaces and apostrophes',
          }
        }
      ],
    },
    {
      selector: '.form-required > .thoroughfare',
      rules: [
        {
          type: 'required',
          message: {
            value: 'Please enter your Address',
          }
        },
       {
       type: 'regex',
       value: /^([0-9a-zA-Z \-'.&âãäåàáæçèéêëìíîïðñòóöőôõøùúûüűřžňýČÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖŐÙÚÛÜŰÝ/])*$/i,
       message: {
       value: 'May contain only letters, numbers, hyphens, spaces, full stops, slashes and apostrophes',
       }
       },
        {
          type: 'min',
          value: 3,
          message: {
            value: Drupal.t('Address has a minimum length of $chars characters'),
            replacements: {
              $chars: 3
            }
          }
        },
        {
          type: 'max',
          value: 80,
          message: {
            value: Drupal.t('Address has a maximum length of $chars characters'),
            replacements: {
              $chars: 80
            }
          }
        }
      ]
    },
    {
      selector: '[name="customer_profile_shipping[commerce_customer_address][und][0][thoroughfare]"]',
      rules: [ 
        {
          type: 'comparison',
          message: {
            value: '$field_label cannot be same as Address line 2.',
            label: 'span.thoroughfare'
          },
          conditions: [
            {
              selector: '[name="customer_profile_shipping[commerce_customer_address][und][0][premise]"]',
              operator: '='
            },
            {
              selector: '[name="customer_profile_shipping[commerce_customer_address][und][0][country]"]',
              operator: '=',
              value: 'GB'
            }
          ]
        }
      ],
    },
    {
      selector: '[name="customer_profile_shipping[commerce_customer_address][und][0][premise]"]',
      rules: [ 
        {
          type: 'comparison',
          message: {
            value: 'Address line 2 cannot be same as Address line 1.',
          },
          conditions: [
            {
              selector: '[name="customer_profile_shipping[commerce_customer_address][und][0][thoroughfare]"]',
              operator: '='
            },
            {
              selector: '[name="customer_profile_shipping[commerce_customer_address][und][0][country]"]',
              operator: '=',
              value: 'GB'
            }
          ]
        }
      ],
    },
    {
      selector: '[name="customer_profile_billing[commerce_customer_address][und][0][thoroughfare]"]',
      rules: [ 
        {
          type: 'comparison',
          message: {
            value: 'Address line 1 cannot be same as Address line 2.',
          },
          conditions: [
            {
              selector: '[name="customer_profile_billing[commerce_customer_address][und][0][premise]"]',
              operator: '='
            },
            {
              selector: '[name="customer_profile_billing[commerce_customer_address][und][0][country]"]',
              operator: '=',
              value: 'GB'
            }
          ]
        }
      ],
    },
    {
      selector: '[name="customer_profile_billing[commerce_customer_address][und][0][premise]"]',
      rules: [ 
        {
          type: 'comparison',
          message: {
            value: 'Address line 2 cannot be same as Address line 1.',
          },
          conditions: [
            {
              selector: '[name="customer_profile_billing[commerce_customer_address][und][0][thoroughfare]"]',
              operator: '='
            },
            {
              selector: '[name="customer_profile_billing[commerce_customer_address][und][0][country]"]',
              operator: '=',
              value: 'GB'
            }
          ]
        }
      ],
    },
    {
      selector: '.form-required > #edit-commerce-fieldgroup-pane-group-phone-number-field-customer-phone-number-und-0-value',
      rules: [
        {
          type: 'required',
          message: {
            value: 'Please enter a phone number which is at least $length digits',
            replacements: {
              $length: 7
            }
          }
        },
        {
          type: 'min',
          value: 7,
          message: {
            value: Drupal.t('Phone number field has a minimum length of $length characters'),
            replacements: {
              $length: 7
            }
          }
        },
        {
          type: 'max',
          value: 11,
          message: {
            value: Drupal.t('Phone number field has a maximum length of $length characters'),
            replacements: {
              $length: 11
            }
          }
        },
        {
          type: 'regex',
          value: /^[^+]+$/i,
          message: {
            value: 'Please enter a phone number without country code',
          },
          conditions: [
            {
              selector: '[name="customer_profile_shipping[commerce_customer_address][und][0][country]"]',
              operator: '=',
              value: 'GB'
            }
          ]
        },
        {
          type: 'regex',
          value: /^(01|02|07)[0-9\s]+$/i,
          message: {
            value: "Must begin with '07' or '01' or '02' and may only contain numbers and spaces",
          },
          conditions: [
            {
              selector: '[name="customer_profile_shipping[commerce_customer_address][und][0][country]"]',
              operator: '=',
              value: 'GB'
            }
          ]
        },
        {
          type: 'regex',
          value: /^(0|\+)[0-9\s]+$/i,
          message: {
            value: 'Phone number must start with a + or 0 and only contain numbers and spaces',
          },
          conditions: [
            {
              selector: '[name="customer_profile_shipping[commerce_customer_address][und][0][country]"]',
              operator: '!=',
              value: 'GB'
            }
          ]
        }
      ]
    }
  ]
};
