(function($) {
  $(function() {
    var viewPortWidth = $(document).outerWidth();
    // Define callbacks for on mouseenter and mouseleave.
    var itemHoverBindings = {
      'mouseenter': function(event) {
        $(this).addClass('main-menu__link_li--level0--active');
      },
      'mouseleave': function(event) {
        $(this).removeClass('main-menu__link_li--level0--active');
      }
    };
    // Bind mouseenter and mouseleave callbacks.
    if (viewPortWidth < 1020) {
      $('.main-menu__link_li--level0').off(Object.keys(itemHoverBindings).join(' '));
    } else {
      $('.main-menu__link_li--level0').on(itemHoverBindings);
    }
    // When in mobile, remove mouseenter and mouseleave callbacks.
    $(window).on('resize', function(event) {
      viewPortWidth = $(this).outerWidth();
      if (viewPortWidth < 1020) {
        $('.main-menu__link_li--level0').off(Object.keys(itemHoverBindings).join(' '));
      } else {
        $('.main-menu__link_li--level0').on(itemHoverBindings);
      }
    });
    // Define and bind callbacks for on click when toggling mobile menu items.
    $('.header__menu-item-toggle').on('click', function(event) {
      // Hide the active items other than the current.
      hideToggleableItems(
          $('.header__menu-item-toggle').not($(this)),
          '',
          'header__menu-item-toggle--active'
      );
      // Toggle the current item.
      hideShowToggleableItems(
          $(this),
          '',
          'header__menu-item-toggle--active'
      );
      // Dynamically determine the maximum height for the nav when in mobile in order to allow for it to be able
      // to scroll when the number of items exceed the height of window viewport.
      var $nav = $(this).next('nav');
      if ($nav.length > 0) {
        $nav.css({'max-height': ($(window).outerHeight() - $nav.position().top) + 'px'});
      }
      event.preventDefault();
    });
    $('.main-menu__link_anchor--level0').on('click', function(event) {
      if ($(this).next('.main-menu--level1').length < 1) {
        return;
      }
      // Hide the active items other than the current.
      hideToggleableItems(
          $('.main-menu__link_li--level0').not($(this).parent()),
          '',
          'main-menu__link_li--level0--active'
      );
      // Toggle the current item.
      hideShowToggleableItems(
          $(this).parent(),
          '',
          'main-menu__link_li--level0--active'
      );
      // Only disable the link follow-through for mobile. A 'see all' option
      // is provided as the alternative.
      if (viewPortWidth < 1020) {
        event.preventDefault();
      }
    });

    // Sticky menu.
    var stickyElementOffsetTop = null;
    $(document).on('scroll touchmove', function(event) {
      viewPortWidth = $(this).outerWidth();
      if (viewPortWidth > 1020) {
        return;
      }
      var stickyElement = $('.header__flex-item--sticky');
      var stickyElementIsFixed = stickyElement.hasClass('header__flex-item--sticky--fixed');
      // Capture it once.
      stickyElementOffsetTop = stickyElementOffsetTop || stickyElement.offset().top;
      // This has been put in place to accommodate the cookie notice that is collapsible at the top. If the
      // sticky element's top offset is less than the captured value, then update.
      if (stickyElement.offset().top < stickyElementOffsetTop) {
        stickyElementOffsetTop = stickyElement.offset().top;
      }
      var documentScrollTop = $(this).scrollTop();
      if (documentScrollTop > stickyElementOffsetTop) {
        if (!stickyElementIsFixed) {
          stickyElement.addClass('header__flex-item--sticky--fixed');
        }
      } else {
        stickyElement.removeClass('header__flex-item--sticky--fixed');
      }
    });
  });

  /**
   * A function to show a toggleable menu items based on the jQuery object set given.
   *
   * @param $items
   */
  function showToggleableItems($items, itemHideClass, itemShowClass) {
    $items.each(function(index, item) {
      replaceItemClass($(item), itemHideClass, itemShowClass);
    });
  }

  /**
   * A function to hide a toggleable menu items based on the jQuery object set given.
   *
   * @param $items
   */
  function hideToggleableItems($items, itemHideClass, itemShowClass) {
    $items.each(function(index, item) {
      replaceItemClass($(item), itemShowClass, itemHideClass);
    });
  }

  /**
   * A function to toggle toggleable menu items based on the jQuery object set given.
   *
   * Note: The item hide class can be empty or have a value, however the item show class cannot.
   *
   * @param $items
   */
  function hideShowToggleableItems($items, itemHideClass, itemShowClass) {
    $items.each(function(index, item) {
      replaceItemClass(
          $(item),
          $(item).hasClass(itemShowClass) ? itemShowClass : itemHideClass,
          $(item).hasClass(itemShowClass) ? itemHideClass : itemShowClass
      );
    });
  }

  /**
   * A function to replace an existing item's class - jQuery object. It will only replace the class if it exists.
   *
   * @param $item
   * @param oldItemClass
   * @param newItemClass
   */
  function replaceItemClass($item, oldItemClass, newItemClass) {
    var itemClass = $item.attr('class');
    var itemClasses = itemClass.split(' ');
    for (var i in itemClasses) {
      // If the old item class exists, remove it.
      if (oldItemClass == itemClasses[i]) {
        itemClass = itemClass.replace(oldItemClass, '');
        // Else if the new item class exists, return. We don't need to do anything.
      } else if (newItemClass == itemClasses[i]) {
        return;
      }
    }
    // Append the new item class.
    $item.attr('class', (itemClass + ' ' + newItemClass).trim());
  }

})(jQuery);
